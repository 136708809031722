import {
  GET_ANALYSES,
  DELETE_ANALYSIS,
  UPDATE_ANALYSIS,
} from 'components/crepes/analysis/AnalysisTypes.js'

const initialState = {
  analyses: [],
}

export const analysisReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_ANALYSES:
    return {
      ...state,
      analyses: action.payload.analyses,
      count: action.payload.count
    }
  case DELETE_ANALYSIS:
    return {
      ...state,
      analyses: state.analyses.filter(
        (item, index) => item.id !== action.payload
      ),
      count: state.count - 1
    }
  case UPDATE_ANALYSIS:
    console.log('UPDATE')
    const updatedAnalyses = state.analyses.map((item) => {
      if (item.id === action.payload.id) {
        console.log(' update state to: ', { ...item, ...action.payload })
        return { ...item, ...action.payload }
      }
      return item
    })
    return {
      ...state,
      analyses: updatedAnalyses,
    }

  default:
    return state
  }
}
