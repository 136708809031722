import axios from 'axios'
import { toastOnError } from 'utils/Utils'
import { GET_IMAGES, UPDATE_IMAGE, DELETE_IMAGE, GET_ACTIVE_ANALYSIS, DELETE_ACTIVE_ANALYSIS } from './ImagesTypes'

export const getActiveAnalysis = (app_id) => (dispatch) => {
  axios
    .get('/api/active_analysis/',  { params: { app_id: `${app_id}` } })
    .then((response) => {
      dispatch({
        type: GET_ACTIVE_ANALYSIS,
        payload: response.data,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}


// TODO: Change this to refresh current page, now if all are deleted, the page is not refreshed
export const deleteActiveAnalysis = (app_id) => (dispatch) => {
  axios
    .delete('/api/active_analysis/', { params: { app_id: `${app_id}` } })
    .then((response) => {
      dispatch({
        type: DELETE_ACTIVE_ANALYSIS,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}

export const getImages = (app_id, page, perPage) => (dispatch) => {
  axios
    .get(`/api/images/${app_id}?is_deleted=false&p=${page}&page_size=${perPage}`)
    .then((response) => {
      dispatch({
        type: GET_IMAGES,
        payload: {images: response.data.results, count: response.data.count},
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}

export const updateImage = (id, image, app_id) => (dispatch) => {
  axios
    .patch(`/api/images/${app_id}/${id}/`, image)
    .then((response) => {
      dispatch({
        type: UPDATE_IMAGE,
        payload: response.data,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}

export const deleteImage = (id, app_id) => (dispatch) => {
  axios
    .delete(`/api/images/${app_id}/${id}`)
    .then((response) => {
      dispatch({
        type: DELETE_IMAGE,
        payload: id,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}
