import axios from 'axios'
import {
  toastOnError
} from 'utils/Utils'
import {
  toast
} from 'react-toastify'
import rateLimit from 'axios-rate-limit'

import 'components/pintura/pintura.css'
import {
  openDefaultEditor
} from 'components/pintura/pintura'
import {
  useDispatch
} from 'react-redux'
import {
  css
} from '@emotion/react'
import BeatLoader from 'react-spinners/BeatLoader'
import HashLoader from 'react-spinners/HashLoader'


import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css'



import {
  setPlugins,
  plugin_crop,
  openEditor,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
} from 'components/pintura/pintura'

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import {
  useDropzone
} from 'react-dropzone'

import {
  Container,
  Button,
  Row,
  Col,
  Alert,
  Toast,
  ToastContainer,
  Form,
  FloatingLabel,
} from 'react-bootstrap'

import {
  addMottling
} from 'components/mottling/MottlingActions'


setPlugins(plugin_crop)

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  padding: 20,
}

const thumb = {
  position: 'relative',
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const thumbButton = {
  position: 'absolute',
  right: 10,
  bottom: 10,
  border: '1px solid black'
}

const thumbClose = {
  position: 'absolute',
  right: 10,
  top: 10,
  border: '1px solid black'
}


const thumbTitle = {
  position: 'absolute',
  left: 10,
  top: -25,
}


const spinnerStyle = {
  position: 'absolute',
  left: -10,
  top: -100,
  zIndex: 9999,
}








function MottlingDrop(props) {


  const [files, setFiles] = useState([])
  const [cropDisabled, setCropDisabled] = useState(false)
  const [dpi, setDpi] = useState(null)




  const [autoCrop, setAutoCrop] = useState(props.ui_settings?.auto_crop_default || false)
  const auto_crop_show = props.ui_settings?.auto_crop_show || false




  // The crop limits in UI settings in mm
  const cropMinPixels = dpi * parseFloat(props.ui_settings.cropMin) / 25.4
  const cropMin = Number.isNaN(cropMinPixels) ? undefined : {
    width: cropMinPixels,
    height: cropMinPixels,
  }

  const cropMaxPixels = dpi * parseFloat(props.ui_settings.cropMax) / 25.4
  const cropMax = Number.isNaN(cropMaxPixels) ? undefined : {
    width: cropMaxPixels,
    height: cropMaxPixels,
  }


  const editImage = (image, done) => {

    const imageFile = image.pintura ? image.pintura.file : image
    const imageState = image.pintura ? image.pintura.data : {}

    const createEditorConfig = (cropMin, cropMax) => {
      const editorConfig = {
        src: imageFile,
        cropEnableInfoIndicator: true,
        utils: ['crop'],
        imageState,
      }

      if (cropMin !== undefined) {
        editorConfig.imageCropMinSize = cropMin
      }

      if (cropMax !== undefined) {
        editorConfig.imageCropMaxSize = cropMax
      }

      if (props.ui_settings.force_square_aspect) {
        editorConfig.imageCropAspectRatio = 1
      }


      return editorConfig
    }

    const editorConfig = createEditorConfig(cropMin, cropMax)
    const editor = openDefaultEditor(editorConfig)



    editor.on('close', () => {
      // the user cancelled editing the image
    })

    editor.on('process', ({
      dest,
      imageState
    }) => {
      Object.assign(dest, {
        pintura: {
          file: imageFile,
          data: imageState
        },
      })
      done(dest)
    })
  }





  const dispatch = useDispatch()

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }


  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        files.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      )
    },
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const override = css`
      display: block
      margin: 0 auto
      top: -20px
    `


  const thumbs = files.map((file, index) => (
    <div>
            <HashLoader color="A8A7A7" loading={cropDisabled} css={override} />
            <div style={thumb} key={file.name}>
                <center style={thumbTitle}>{file.name}</center>
                <div style={thumbInner}>
                    <img src={file.preview} style={img} alt="" />
                </div>

                <button
                    className="btn btn-danger btn-sm" // Add the Bootstrap class names for the desired styling
                    style={thumbClose}
                    disabled={cropDisabled}
                    onClick={() => {
                        console.log(files)
                        const updatedFiles = [...files]
                        updatedFiles.splice(index, 1)
                        console.log(updatedFiles)
                        setFiles(updatedFiles)
                        if (file.preview) URL.revokeObjectURL(file.preview)
                    }}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>

                <button
                    style={thumbButton}
                    className="btn btn-light btn-sm"
                    disabled={cropDisabled}
                    onClick={() =>
                        editImage(file, (output) => {
                            const updatedFiles = [...files]
                            updatedFiles[index] = output
                            if (file.preview) URL.revokeObjectURL(file.preview)
                            Object.assign(output, {
                                preview: URL.createObjectURL(output),
                            })
                            setFiles(updatedFiles)
                        })
                    }
                >
                    Crop
                </button>
            </div>
        </div>
  ))

  useEffect(
    () => () => {
      //files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )




  useEffect(() => {
    console.log('SET DPI EFFECT')
    console.log(props.ui_settings.dpi_options)
    if (props.ui_settings.dpi_options) {
      setDpi(props.ui_settings.dpi_options[0])
      console.log('SET DPI TO', props.ui_settings.dpi_options[0])
    } else {
      setDpi(1200)
      console.log('SET DPI TO 1200')
    }
  }, [props.ui_settings])


  // TODO: Make the texts red if area is not enough, also maybe disable the done button
  useEffect(() => {

    clearImages()


    const interval = setInterval(() => {
      var imagei = document.querySelector('.PinturaImageInfo')
      if (!imagei) return
      var size_p = document.querySelector('.PinturaImageInfo').querySelector('p')
      var size_pixels = (parseFloat(size_p.textContent.split(' ')))
      if (imagei.childElementCount == 1) {
        var y = document.createElement('div')
        imagei.appendChild(y)

        var d = document.createElement('div')
        d.textContent = dpi + ' DPI'
        imagei.appendChild(d)

      }
      var y = document.querySelector('.PinturaImageInfo').querySelector('div')
      var size_in = (size_pixels / parseFloat(dpi))
      var size_mm = (size_in * 2.54).toFixed(2)
      y.textContent = `${size_mm} x ${size_mm} cm`
    }, 500)
    return () => clearInterval(interval)
  }, [dpi])


  const rateAxios = rateLimit(axios.create(), {
    maxRequests: 1,
    perMilliseconds: 1000,
    maxRPS: 1
  })

  const axiosUploader = rateLimit(axios.create(), {
    maxRequests: 1,
    perMilliseconds: 1000,
    maxRPS: 1
  })

  delete axiosUploader.defaults.headers.common['Authorization']



  const uploadImages = () => {
    console.log(files)
    setCropDisabled(true)

    files.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))

    for (var i = 0; i < files.length; i++) {
      let file = files[i]
      let formData = new FormData()

      formData.append('fn', file.name)
      formData.append('app_id', props.app_id)
      formData.append('auto_crop', autoCrop)

      if (props.ui_settings.dpi_options) {
        formData.append('dpi', dpi)
      }

      rateAxios
        .post('/api/upload_mottling/', formData)
        .then(function(response) {
          const presignedUrl = response.data.url
          console.log(presignedUrl)

          return axiosUploader.put(presignedUrl, file, {
            headers: {
              'Content-Type': file.type,
            },
          })
        })
        .then(() => {
          toast.success('Uploaded ' + file.name)
          clearImages()
        })
        .catch((error) => {
          toastOnError(error)
          clearImages()
        })
    }
  }

  const clearImages = () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview))
    setFiles([])
    setCropDisabled(false)
  }

  return (
    <Row>
            <Col md={8}>
                <section className="container">
                    <div style={style} {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drop images here or click to select images</p>
                    </div>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                </section>
            </Col>
            <Col md={4}>
                {props.ui_settings.dpi_options &&
                    <React.Fragment>
                        <FloatingLabel controlId="floatingSelect" label="DPI">
                            <Form.Select
                                aria-label="DPI selection"
                                onChange={(e) => setDpi(parseInt(e.target.value))}
                            >
                                {props.ui_settings.dpi_options.map(o =>
                                    <option value={o}>{o}</option>
                                )}
                            </Form.Select>
                        </FloatingLabel>
                        <br />
                    </React.Fragment>
                }

            <React.Fragment>
            <Form>
            {auto_crop_show && (
              <Form.Check
              type="checkbox"
              id="autoCropCheck"
              label="Use auto crop"
              checked={autoCrop}
              onChange={(e) => setAutoCrop(e.target.checked)}
              />
            )}
            <br />
            </Form>
            </React.Fragment>



                <Row>
                    <Col xs={6}>
                        <Button variant="outline-primary" onClick={uploadImages}>
                            Upload images
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button variant="outline-primary" onClick={clearImages}>
                            Clear images
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
  )
}

export default MottlingDrop
