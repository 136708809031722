import React, { useEffect, useState } from 'react'
import DropZone from 'components/Dropzone'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import {
  Container,
  Button,
  ButtonGroup,
  Row, Col,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton'
import TopNav from 'components/TopNav'
import DataTable from 'react-data-table-component'
import Image from 'react-bootstrap/Image'

import { useNavigate } from 'react-router-dom'

import { connect } from 'react-redux'
import { getImages, deleteImage } from 'components/crepes/images/ImagesActions'
import { getActiveAnalysis, deleteActiveAnalysis } from 'components/crepes/images/ImagesActions'
import { addAnalysis } from 'components/crepes/analysis/AnalysisActions'

import Modal from 'react-modal'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'


function Plot(props) {
  const data = props.data.map((d) => {
    console.log(d)
    const obj = Object.assign({}, d)

    obj['name'] = `${d.name}\n(${d.timestamp})`
    return obj
  })
  console.log(props.data)

  return (
    <ResponsiveContainer width="90%" height="90%">
      <LineChart
        data={data}
        margin={{
          top: 50,
          right: 10,
          left: 30,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis />
        <Tooltip
          formatter={(value, name, props) => [value.toFixed(1), 'c/cm']}
        />

        <Legend formatter={(value, entry, index) => 'Crepe count'} />
        <Line
          type="monotone"
          dataKey="crepe_count"
          stroke="#8884d8"
          activeDot={{ r: 3 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}


function ImageDetails(props) {
  return (
    <Container>
      <Row>
        <Col>
          <Image width="95%" src={props.data.output} />
        </Col>
        <Col>
          <Image width="95%" src={props.data.spectrum} />
        </Col>
        <Col>
          <Image width="95%" src={props.data.rad_spectrum} />
        </Col>
      </Row>
    </Container>
  )
}

function ImagesTable(props) {


  const dispatch = useDispatch()

  const columns = [
    {
      name: 'Timestamp',
      selector: (row) => {
        return new Date(row.created_at).toLocaleString('fi-FI')
      },
    },
    {
      name: 'Name',
      selector: (row) => row.name,
    },
    {
      name: 'Crepe count (c/cm)',
      selector: (row) => (
        <h6>{row.crepe_count && row.crepe_count.toFixed(2)}</h6>
      ),
    },
    {
      name: 'Part of analysis',
      selector: (row) => row.analysis_set.map((aset) => <div>{aset.name}</div>),
    },
  ]
  const data = props.data

  const history = useNavigate()

  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)


  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        const ids = selectedRows.map((r) => r.id)
        console.log('ids: ', ids)
        for (const id of ids) {
          console.log('id: ', id)
          dispatch(deleteImage(id, props.app_id))
        }
        setToggleCleared(!toggleCleared)
      }
    }

    const routeChange = () => {
      //let path = "analysis/";
      //history.push(path);
      return
    }

    const handleCreateAnalysis = () => {
      const analysis = {
        name: 'New analysis',
        inputimages: selectedRows.map((r) => r.id),
      }
      console.log(analysis)
      dispatch(addAnalysis(analysis, props.app_id))
      setToggleCleared(!toggleCleared)
      routeChange()
    }



    const handleExport = () => {
      const ids = selectedRows.map((r) => r.id)

      axios({
        url: '/api/crepe_image_export/',
        method: 'POST',
        responseType: 'blob',
        data: { ids: ids },
      }).then((response) => {
        console.log(response)

        let blob = new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          url = window.URL.createObjectURL(blob)

        window.open(url)
      })
    }

    return (
      <Container>


        <ButtonGroup>
        <Button
          className="ml-5"
          variant="primary"
          key="plot"
          onClick={() => props.openPlot(selectedRows)}
          icon
        >
              Plot
        </Button>

        <Button
          style={{ whiteSpace: 'nowrap' }}
          variant="info"
          key="create"
          onClick={handleCreateAnalysis}
        >
              New analysis
        </Button>

        <Button
          style={{ whiteSpace: 'nowrap' }}
          variant="info"
          key="create"
          onClick={handleExport}
        >
              Export
        </Button>

        <Button
          variant="danger btn-sm"
          key="delete"
          onClick={handleDelete}
          icon="true"
        >
              Delete
        </Button>

        </ButtonGroup>

      </Container>
    )
  }, [data, selectedRows, toggleCleared])

  const deactivateAnalysis = () => {
    console.log('Clicked')
    dispatch(deleteActiveAnalysis(props.app_id))
  }



  return (
    <div>
      {props.activeAnalysis.active_analysis && (
        <ToastContainer
          className="p-3"
          position={'bottom-start'}
          style={{ zIndex: 9999 }}
        >
          <Toast>
            <Toast.Header closeButton={false}>
              <strong className="me-auto">Active analysis</strong>
              <small>Expires in {props.activeAnalysis.timeleft} min</small>
              <CloseButton aria-label="Hide" onClick={deactivateAnalysis} />
            </Toast.Header>

            <Toast.Body>
              <div>
                <b>{props.activeAnalysis.active_analysis}</b>
              </div>
              <div>
                <small>
                  New images will be automatically added to active analysis.
                </small>
              </div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <br />
      <DropZone key={props.app_id} app_id={props.app_id}/>

      <DataTable
        key={props.app_id + 'datatable'}
        title="Crepe input images"
        columns={columns}
        data={data}
        selectableRows
        contextActions={contextActions}
        persistTableHead
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        pagination
        paginationServer
        paginationTotalRows={props.totalRows}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        onChangePage={props.handlePageChange}
        onChangeRowsPerPage={props.handlePerRowsChange}
        expandableRows
        expandableRowsComponent={ImageDetails}
      />
    </div>
  )
}

function Images(props) {


  const [modalIsOpen, setIsOpen] = useState(false)
  const [plotData, setPlotData] = useState([])

  const openPlot = (data) => {
    console.log('Open')
    setIsOpen(true)
    setPlotData(
      data.map((r) => ({
        name: r.name,
        crepe_count: r.crepe_count,
        timestamp: new Date(r.created_at).toLocaleDateString('fi-FI'),
      }))
    )
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const modalStyle = {

    overlay: {
      zIndex: 999,
    },

    content: {
      top: '20%',
      left: '10%',
      right: '10%',
      bottom: '20%',
      marginRight: '0%',
      //transform: "translate(-50%, -50%)",
      backdropFilter: 'blur(18px)',
    },
  }



  const dispatch = useDispatch()
  const state = useSelector(state => state.images)
  //const images = state.images.filter(id=props.app_id)
  console.log(state)

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const handlePageChange = newPage => {
    setPage(newPage)
  }
  const handlePerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage)
  }

  console.log(props)
  useEffect(() => {
    dispatch(getActiveAnalysis(props.app_id))
    dispatch(getImages(props.app_id, page, perPage))
    // DELAY FOR IMAGES IN SECONDS
    const delay = 5

    // TODO: This is bugging the system, need to update the page value on each call, maybe reinitialize the timers on prop changes
    let timer = setInterval(() => dispatch(getImages(props.app_id, page, perPage)), delay * 1000)
    let timer2 = setInterval(() => dispatch(getActiveAnalysis(props.app_id)), 11 * 1000)

    return () => {
      clearInterval(timer)
      clearInterval(timer2)
    }
  }, [props.app_id, page, perPage])

  return (
    <React.Fragment>
      <React.Fragment>
        <TopNav />
      </React.Fragment>
      <Container>

        <ImagesTable
          data={state.images}
          openPlot={openPlot}
          app_id={props.app_id}
          activeAnalysis={state.active_analysis}
          addAnalysis={addAnalysis}
          deleteImage={deleteImage}
          deleteActiveAnalysis={deleteActiveAnalysis}
          totalRows={state.count}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerPageChange}
        />
      </Container>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
          contentLabel="Plot"
        >
          <Plot data={plotData} />
        </Modal>
      </div>



    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  state: state.images,
})

export default Images



// Try new types of buttons for mobile screens. Maybe footer could also work.
      //{selectedRows.length > 0 ?
          //<React.Fragment>
          //<center>
          //<button class="btn btn-primary ml-5 mr-5">Plot</button>
          //<button class="btn btn-inf ml-5 mr-5">New analysis</button>
          //<button class="btn btn-info ml-5 mr-5">Export</button>
          //<button class="btn btn-danger ml-5 mr-5">Delete</button>
          //</center>
          //</React.Fragment>
          //:
          //<React.Fragment>
          //<br/>
          //<br/>
          //</React.Fragment>
          //}

