import axios from 'axios'
import { toast } from 'react-toastify'

export const setAxiosAuthToken = (token) => {
  if (typeof token !== 'undefined' && token) {
    // Apply for every request
    axios.defaults.headers.common['Authorization'] = 'Token ' + token
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization']
  }
}

export const toastOnError = (error) => {
  if (error.response) {
    // known error
    toast.error(JSON.stringify(error.response.data))
  } else if (error.message) {
    toast.error((error.message))
  } 
  else if (error.status) {
      toast.error(("Error response " + error.status))
  }
  else {
    toast.error(JSON.stringify(error))
  }
}

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)
