import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import TopNav from 'components/TopNav'

import LicenseTerms from 'components/LicenseTerms'
import crepe_image from 'static/crepe.jpg'
import mottling_image from 'static/mottling.png'
import graininess_image from 'static/graininess.png'

import axios from 'axios'

function Home(props) {

  useEffect(() => {
    axios.get('/api/heartbeat/')
  }, [])


  const [showLicense, setShowLicense] = useState(false)
  const handleClose = () => setShowLicense(false)
  const handleShow = () => setShowLicense(true)

  return (

    <React.Fragment>
      <React.Fragment>
        <TopNav />
      </React.Fragment>
      <Container>
        <br/>
        <br/>
        <div><h4>Welcome to PapEye CloudQCS</h4></div>
        <br/>

        <p>
          <big>
For support, please contact <a href="info@tapiotechnologies.com">info@tapiotechnologies.com</a>
          </big>
        </p>

        <div className="d-flex flex-column-reverse license-link">
          <div className="d-flex justify-content-end">
            <a href="#" onClick={handleShow}>papeye.com License Terms</a>
          </div>
        </div>


          <LicenseTerms show={showLicense} handleClose={handleClose} />

      </Container>
    </React.Fragment>
  )
}
export default Home
