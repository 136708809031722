import React from 'react'
import Root from './Root'

import { makeServer } from 'mirage-server'

import TabRouter from './TabRouter'
import {
  ToastContainer
} from 'react-toastify'


import Home from 'components/Home'
import {
  EnvContext
} from 'config'
import axios from 'axios'

var env = ''


if (window.location.origin === 'http://localhost:3000') {
  axios.defaults.baseURL = 'http://127.0.0.1:8000'
  //makeServer({ environment: 'development' })
  env = 'LOCAL'
} else if (window.location.origin === 'https://development.papeye.com') {
  axios.defaults.baseURL = 'https://backend-development.papeye.com'
  env = 'DEVELOPMENT'
} else if (window.location.origin === 'https://www.papeye.com') {
  axios.defaults.baseURL = 'https://backend.papeye.com'
  env = 'PRODUCTION'
} else if (window.location.origin === 'https://papeye.com') {
  axios.defaults.baseURL = 'https://backend.papeye.com'
  env = 'PRODUCTION'
}







function App(props) {
  return (
    <EnvContext.Provider value={env}>
      <div>
        <Root>
          <ToastContainer hideProgressBar={true} newestOnTop={true} />
          <TabRouter/>
        </Root>
      </div>
    </EnvContext.Provider>
  )
}

export default App
