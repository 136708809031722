import React, {
  useEffect,
  useState
} from 'react'
import {
  toast
} from 'react-toastify'
import Form from 'react-bootstrap/Form'
import {
  Container,
  Button,
  ButtonGroup,
  Row,
  Col
} from 'react-bootstrap'
import TopNav from 'components/TopNav'
import DataTable from 'react-data-table-component'
import {
  getAnalyses,
  deleteAnalysis,
  updateAnalysis
} from './AnalysisActions'

import Modal from 'react-modal'
import axios from 'axios'
import {
  useDispatch,
  useSelector
} from 'react-redux'

import {
  connect
} from 'react-redux'
import Table from 'react-bootstrap/Table'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

function AnalysisDetails(props) {

  const dispatch = useDispatch()

  const data = props.data

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('enter')
      props.handleChangeName(props.data.id)(event)
      event.preventDefault()
    }
  }


  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={e => { e.preventDefault() }}>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Edit name</Form.Label>
              <Form.Control
                placeholder={data.name}
                onBlur={props.handleChangeName(data.id)}
                onKeyDown = {handleKeyDown}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Image</th>
                <th>c/cm</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.inputimages.map((r) => (
                <tr>
                  <td>{r.name}</td>
                  <td>{r.crepe_count && r.crepe_count.toFixed(3)}</td>
                  <td>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={props.handleRemoveImage(data, r.id)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

function Plot(props) {
  const data = props.data.map((d) => {
    console.log(d)
    const obj = Object.assign({}, d)

    obj['name'] = `${d.name}\n(${d.timestamp})`
    return obj
  })
  console.log(props.data)

  return (
    <ResponsiveContainer width="90%" height="90%">
      <LineChart
        data={data}
        margin={{
          top: 50,
          right: 10,
          left: 30,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis />
        <Tooltip
          formatter={(value, name, props) => [value.toFixed(1), 'c/cm']}
        />

        <Legend formatter={(value, entry, index) => 'Average crepe count'} />
        <Line
          type="monotone"
          dataKey="average_crepe_count"
          stroke="#8884d8"
          activeDot={{ r: 3 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

function AnalysisTable(props) {

  const dispatch = useDispatch()

  const handleChangeName = (id) => (event) => {
    if (event.target.value.length === 0) return
    dispatch(updateAnalysis(id, {
      name: event.target.value,
    }, props.app_id))
    toast.success('Saved name')
  }

  const handleRemoveImage = (analysis, image_id) => (event) => {
    dispatch(updateAnalysis(analysis.id, {
      inputimages: analysis.inputimages
        .filter((i) => i.id !== image_id)
        .map((i) => i.id),
    }, props.app_id))
  }

  const columns = [{
      name: 'Timestamp',
      selector: (row) => {
        return new Date(row.created_at).toLocaleString('fi-FI')
      },
    },
    {
      name: 'Name',
      selector: (row) => row.name,
    },
    {
      name: 'Number of images',
      selector: (row) => row.inputimages.length,
    },
    {
      name: 'Average crepes (c/cm)',
      selector: (row) => <h6>{row.average_crepe_count.toFixed(2)}</h6>,
    },
  ]

  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const data = props.data

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        const ids = selectedRows.map((r) => r.id)
        console.log('ids: ', ids)
        for (const id of ids) {
          console.log('id: ', id)
          dispatch(deleteAnalysis(id, props.app_id))
        }
        setToggleCleared(!toggleCleared)
      }
    }

    const handleExport = () => {
      const ids = selectedRows.map((r) => r.id)

      // Refactor these into a single export function
      // Also refactor: dropzone into a parametrized component
      axios({
        url: '/api/crepe_analysis_export/',
        method: 'POST',
        responseType: 'blob',
        data: {
          ids: ids
        },
      }).then((response) => {
        console.log(response)

        let blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          url = window.URL.createObjectURL(blob)

        window.open(url)
      })
    }

    return (
      <Container>
        <ButtonGroup>
          <Button
            variant="primary"
            key="plot"
            onClick={() => props.openPlot(selectedRows)}
            icon
          >
              Plot
          </Button>
          <Button
            style={{ whiteSpace: 'nowrap' }}
            variant="info"
            key="create"
            onClick={handleExport}
          >
              Export
          </Button>
          <Button variant="danger" key="delete" onClick={handleDelete} icon>
              Delete
          </Button>
        </ButtonGroup>
      </Container>
    )
  }, [data, selectedRows, toggleCleared])

  return (
    <DataTable
      title="Crepe trend analysis"
      columns={columns}
      data={data}
      selectableRows
      contextActions={contextActions}
      onSelectedRowsChange={handleRowSelected}
      clearSelectedRows={toggleCleared}
      pagination
      paginationServer
      paginationTotalRows={props.totalRows}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      onChangePage={props.handlePageChange}
      onChangeRowsPerPage={props.handlePerRowsChange}
      expandableRows
      expandableRowsComponent={AnalysisDetails}
      expandableRowsComponentProps={{
        handleChangeName: handleChangeName,
        handleRemoveImage: handleRemoveImage,
      }}
    />
  )
}

function Analysis(props) {

  const dispatch = useDispatch()


  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const handlePageChange = newPage => {
    setPage(newPage)
  }
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage)
  }


  useEffect(() => {
    dispatch(getAnalyses(props.app_id, page, perPage))
    // DELAY FOR IMAGES IN SECONDS
    const delay = 5
    let timer = setInterval(() => dispatch(getAnalyses(props.app_id, page, perPage)), delay * 1000)
    return () => {
      clearInterval(timer)
    }
  }, [props.app_id, page, perPage])

  const [modalIsOpen, setIsOpen] = useState(false)
  const [plotData, setPlotData] = useState([])

  const openPlot = (data) => {
    console.log('Open')
    setIsOpen(true)
    setPlotData(
      data.map((r) => ({
        name: r.name,
        average_crepe_count: r.average_crepe_count,
        timestamp: new Date(r.created_at).toLocaleDateString('fi-FI'),
      }))
    )
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const modalStyle = {

    overlay: {
      zIndex: 999,
    },

    content: {
      top: '20%',
      left: '10%',
      right: '10%',
      bottom: '20%',
      marginRight: '0%',
      //transform: "translate(-50%, -50%)",
      backdropFilter: 'blur(18px)',
    },
  }


  const state = useSelector(state => state.analyses)

  return (
    <React.Fragment>
      <React.Fragment>
        <TopNav />
      </React.Fragment>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
          contentLabel="Plot"
        >
          <Plot data={plotData} />
        </Modal>
      </div>
      <Container>
        <AnalysisTable
          app_id={props.app_id}
          data={state.analyses}
          openPlot={openPlot}
          totalRows={state.count}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
        />
      </Container>
    </React.Fragment>
  )
}


export default Analysis
