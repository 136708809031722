import React, { useEffect, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { logout } from 'components/login/LoginActions'
import { LinkContainer } from 'react-router-bootstrap'

import { useNavigate } from 'react-router-dom'
import logo from 'static/tapio_logo.png'
import { EnvContext } from 'config'
import { Outlet, NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'

function Crepe(props) {
  return (

    <NavDropdown title={props.ui_settings.title} id="basic-nav-dropdown">
      <LinkContainer to={`/${props.name}/images`}>
        <NavDropdown.Item>Input images</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${props.name}/analysis`}>
        <NavDropdown.Item>Trend analysis</NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
  )
}

function Mottling(props) {
  return (
    <Nav.Link as={NavLink} to={`/${props.name}`}>
      {props.ui_settings.title}
    </Nav.Link>
  )
}

function Tabs(props) {
  var tabs = []

  if (props.tabs) {
    props.tabs.forEach(function (element, index) {
      console.log(element)

      const targetNames = ['MOTTLING', 'GRAININESS'];
      if (targetNames.includes(element.app_type.name)) tabs.push(<Mottling key={element.id} name={element.name} ui_settings={element.ui_settings}/>)
      if (element.app_type.name === 'CREPE') tabs.push(<Crepe key={element.id} name={element.name} ui_settings={element.ui_settings}/>)
    })
  }

  return <React.Fragment>{tabs}</React.Fragment>
}

function TopNav(props) {
  const navigate = useNavigate()


  const onLogout = () => {
    props.logout(navigate)
  }

  const env = React.useContext(EnvContext)

  const EnvText = () => {
    if (env != 'PRODUCTION') {
      return (
        <center>
          <span style={{ color: 'red' }}>{env}</span>
          <br />
        </center>
      )
    }
    return
  }

  return (
    <Navbar bg="light" expand="md">
      <Container>

        <Navbar.Brand as={NavLink} to="/home">

          {EnvText()}
          <img
            height="40"
            className="d-inline-block align-top"
            src={logo}
            alt="Tapio Technologies logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Tabs tabs={props.auth.user.tabs} />
          </Nav>
          <Navbar.Collapse className="justify-content-end">



      {props.auth.user.username ?
          <React.Fragment>
          <Navbar.Text>
          <div>
          User: <b>{props.auth.user.username}</b>
          </div>
          {props.active_analysis && (


              <div>
              Active analysis: <b>{props.active_analysis}</b>
              </div>
          )}
          </Navbar.Text>
          <Nav.Link onClick={onLogout}>Logout</Nav.Link>
          </React.Fragment>
          :
          <Navbar.Text>
          <div>
          <Nav.Link as={NavLink} to="/login">Log in</Nav.Link>
          </div>
          </Navbar.Text>
      }


          </Navbar.Collapse>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

TopNav.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, {
  logout,
})(TopNav)
