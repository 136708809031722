import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ProtectedRoute } from 'utils/ProtectedRoute'
import Login from 'components/login/Login'
import Home from 'components/Home'
import Ad from 'components/Ad'
import Analysis from 'components/crepes/analysis/Analysis'
import Images from 'components/crepes/images/Images'
import Mottling from 'components/mottling/Mottling'



function TabRouter(props)  {
    console.log('TAB ROUTER')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth.isAuthenticated)
    const tabs = useSelector(state => state.auth.user.tabs)

    console.log(tabs)

    // Found this way to force login. Is protected route even needed?
    useEffect(() => {
        if (!auth){
            return navigate("/");
        }
    },[auth])


    function CreateRoutes() {
        var routes = []

        if (tabs) {
            tabs.forEach(function (element, index) {
                const targetNames = ['MOTTLING', 'GRAININESS']
                if (targetNames.includes(element.app_type.name)) routes.push(
                    <Route key={element.id} path={`/${element.name}`} element={
                        <ProtectedRoute isAuthenticated={auth}>
                        <Mottling key={props.app_id + "mottling"} app_id={element.id} ui_settings={element.ui_settings}/>
                        </ProtectedRoute>
                    }
                    />
                )
                if (element.app_type.name === 'CREPE') routes.push(
                    <React.Fragment>
                    <Route key={element.id} path={`/${element.name}/images/`} element={
                        <ProtectedRoute isAuthenticated={auth}>
                        <Images key={props.app_id + "images"} app_id={element.id} ui_settings={element.ui_settings}/>
                        </ProtectedRoute>
                    }
                    />
                    <Route path={`/${element.name}/analysis/`} element={
                        <ProtectedRoute isAuthenticated={auth}>
                        <Analysis key={props.app_id + "analysis"} app_id={element.id} ui_settings={element.ui_settings}/>
                        </ProtectedRoute>
                    }
                    />
                    </React.Fragment>
                )
            })
        }

        return <React.Fragment>{routes}</React.Fragment>
    }



    return (
        <React.Fragment>
        <Routes>
        <Route exact path="/" element={ <Ad/> } />
        <Route path="/login" element={<Login/>} />
        <Route path="/home" element={

            <ProtectedRoute isAuthenticated={auth}>
            <Home/>
            </ProtectedRoute>
        }
        />
        {CreateRoutes()}
        </Routes>
        </React.Fragment>
    )
}



export default TabRouter
