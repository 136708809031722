import { combineReducers } from 'redux'

import { loginReducer } from 'components/login/LoginReducer'
import { imagesReducer } from 'components/crepes/images/ImagesReducer'
import { analysisReducer } from 'components/crepes/analysis/AnalysisReducer'
import { mottlingReducer } from 'components/mottling/MottlingReducer'

const createRootReducer = () =>
  combineReducers({
    auth: loginReducer,
    images: imagesReducer,
    analyses: analysisReducer,
    mottlings: mottlingReducer,
  })

export default createRootReducer
