import { GET_MOTTLINGS, DELETE_MOTTLING, ADD_MOTTLING, UPDATE_MOTTLING } from './MottlingTypes'

import axios from 'axios'
import { toastOnError } from 'utils/Utils'

export const getMottlings = (app_id, page, perPage) => (dispatch) => {
  // TODO: logic for count
  axios
    .get(`/api/mottling/${app_id}?is_deleted=false&p=${page}&page_size=${perPage}`)
    .then((response) => {
      dispatch({
        type: GET_MOTTLINGS,
        payload: {mottlings: response.data.results, count: response.data.count},
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}

export const deleteMottling = (id, app_id) => (dispatch) => {
  console.log('delete mottling')
  console.log(id)
  axios
    .delete(`/api/mottling/${app_id}/${id}`)
    .then((response) => {
      dispatch({
        type: DELETE_MOTTLING,
        payload: id,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}

export const addMottling = (mottling, app_id) => (dispatch) => {
  dispatch({
    type: ADD_MOTTLING,
    payload: mottling,
  })
}


export const updateMottling = (id, mottling, app_id) => (dispatch) => {
  console.log("UPDATE MOTTLING")
  console.log(mottling)
  axios
    .patch(`/api/mottling/${app_id}/${id}`, mottling)
    .then((response) => {
      return axios.get(`/api/mottling/${app_id}/${id}`)
    })
    .then((response) => {
      dispatch({
        type: UPDATE_MOTTLING,
        payload: response.data,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}
