import Dropzone from 'react-dropzone'
import {
  useState,
  useEffect,
  useCallback
} from 'react'
import axios from 'axios'
import {
  toastOnError
} from 'utils/Utils'
import {
  toast
} from 'react-toastify'
import rateLimit from 'axios-rate-limit'

import React, {
  useMemo
} from 'react'
import {
  useDropzone
} from 'react-dropzone'

function DropZone(props) {
  const [files, setFiles] = useState([])

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
      setFiles([])
    },
    [props.app_id]
  )



  const thumbsContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumbStyle = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  }

  const thumbInnerStyle = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  }

  const imgStyle = {
    display: 'block',
    width: 'auto',
    height: '100%',
  }

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }

  const thumbs = files.map((file) => (
    <div style={thumbStyle} key={file.name}>
      <div style={thumbInnerStyle}>
        <img src={file.preview} style={imgStyle} />
      </div>
    </div>
  ))

  const rateAxios = rateLimit(axios.create(), {
    maxRequests: 1,
    perMilliseconds: 1000,
    maxRPS: 1
  })

  const axiosUploader = rateLimit(axios.create(), {
    maxRequests: 1,
    perMilliseconds: 1000,
    maxRPS: 1
  })

  delete axiosUploader.defaults.headers.common['Authorization']



  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    )

    for (var i = 0; i < acceptedFiles.length; i++) {
      let file = acceptedFiles[i]
      let formData = new FormData()
      formData.append('fn', file.name)
      formData.append('app_id', props.app_id)

      rateAxios
        .post('/api/upload_crepe/', formData)
        .then(async function(response) {
          const presignedUrl = response.data.url
          console.log(presignedUrl)

          try {
            const result = await axiosUploader.put(presignedUrl, file, {
              headers: {
                'Content-Type': file.type,
                //'Authorization': undefined
              },
            })
            toast.success('Uploaded ' + file.name)
          } catch (error) {
            toastOnError(error)
          }
        })
        .catch((error) => {
          toastOnError(error)
        })
    }
  }, [])

  const onDropRejected = (rejections) => {
    rejections.map(({
      file,
      errors
    }) => {
      console.log('rejected ', file.name, errors[0].message)
      toast.error('Rejected ' + file.name + ': ' + errors[0].message)
    })
  }

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: 'image/jpeg, image/png, image/bmp, image/tiff',
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <React.Fragment>
      {/*<h5>{props.app_id}</h5>*/}
      <div style={style} {...getRootProps(style)}>
        <input {...getInputProps()} />
        {<p>Drop images here or click to select images</p>}
      </div>
      <aside style={thumbsContainerStyle}>{thumbs}</aside>
    </React.Fragment>
  )
}

export default DropZone
