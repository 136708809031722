import axios from 'axios'
import { toastOnError } from 'utils/Utils'
import {
  GET_ANALYSES,
  ADD_ANALYSIS,
  DELETE_ANALYSIS,
  UPDATE_ANALYSIS,
} from 'components/crepes/analysis/AnalysisTypes'

export const getAnalyses = (app_id, page, perPage) => (dispatch) => {
  axios
    .get(`/api/analyses/${app_id}?is_deleted=false&p=${page}&page_size=${perPage}`)
    .then((response) => {
      dispatch({
        type: GET_ANALYSES,
        payload: {analyses: response.data.results, count: response.data.count},
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}

export const addAnalysis = (analysis, app_id) => (dispatch) => {
  axios
    .post(`/api/analyses/${app_id}`, analysis)
    .then((response) => {
      dispatch({
        type: ADD_ANALYSIS,
        payload: response.data,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}

export const deleteAnalysis = (id, app_id) => (dispatch) => {
  axios
    .delete(`/api/analyses/${app_id}/${id}`)
    .then((response) => {
      dispatch({
        type: DELETE_ANALYSIS,
        payload: id,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}



export const updateAnalysis = (id, analysis, app_id) => (dispatch) => {
  // cannot patch nested objects in DRF, but need them in response. Therefore get after patch
  axios
    .patch(`/api/analyses/${app_id}/${id}`, analysis)
    .then((response) => {
      return axios.get(`/api/analyses/${app_id}/${id}`)
    })
    .then((response) => {
      dispatch({
        type: UPDATE_ANALYSIS,
        payload: response.data,
      })
    })
    .catch((error) => {
      toastOnError(error)
    })
}
