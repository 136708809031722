// TODO This is really similar to crepe reducer, maybe they should be harmonized

import {
  GET_MOTTLINGS,
  ADD_MOTTLING,
  DELETE_MOTTLING,
  UPDATE_MOTTLING,
} from './MottlingTypes.js'

const initialState = {
  mottlings: [],
}

export const mottlingReducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_MOTTLING:
    return {
      ...state,
      mottlings: [action.payload, ...state.mottlings],
      count: state.count + 1
    }
  case GET_MOTTLINGS:
    return {
      ...state,
      mottlings: action.payload.mottlings,
      count: action.payload.count
    }
  case DELETE_MOTTLING:
    return {
      ...state,
      mottlings: state.mottlings.filter(
        (item, index) => item.id !== action.payload
      ),
      count: state.count - 1
    }

  case UPDATE_MOTTLING:
    const updatedMottlings = state.mottlings.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, ...action.payload }
      }
      return item
    })
    return {
      ...state,
      mottlings: updatedMottlings,
    }


  default:
    return state
  }
}
