
import React from 'react'

import {
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
} from 'react-router-dom'


export const ProtectedRoute = ({
  isAuthenticated,
  children,
}) => {
    console.log("Protected route ", isAuthenticated)
  if (!isAuthenticated) {
    return <Navigate to={'/'} replace />
  }
  return children ? children : <Outlet />
}
