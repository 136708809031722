import React, {
  useEffect, useState
} from 'react'
import {
  Container
} from 'react-bootstrap'
import TopNav from 'components/TopNav'

import LicenseTerms from 'components/LicenseTerms'

import crepe_image from 'static/crepe.jpg'
import mottling_image from 'static/mottling.png'
import graininess_image from 'static/graininess.png'

import axios from 'axios'

function Ad(props) {

  useEffect(() => {
      axios.get(`/api/heartbeat/`)
  }, [])



  return (

    <React.Fragment>

        <style type="text/css">
            {`


            .card-body {
              display: flex;
              flex-direction: column;
            }



            .card-footer {
              background: transparent;
              border-top: 0px;
              flex: 1;
            }

                .card-title {
                  margin-top: 10px;
                  margin-bottom: 20px;
                }

                .margins {
                  margin-top: 30px;
                  margin-bottom: 15px;
                }


                .btn {
                  margin-top: 15px;
                }

                .card {
                    margin-bottom: 25px;
                    margin-right: 5px;
                    margin-left: 5px;
                }

                .license-link {
                    margin-bottom: 1rem;
                }


        `}
          </style>


        <React.Fragment>
        <TopNav />
        </React.Fragment>
        <Container>
        <br/>
        <br/>
        <div><h4>PapEye CloudQCS</h4></div>
        <br/>

        <p>
        <big>
Tapio PapEye CloudQCS is a browser-based quality control and analysis platform for manufacturers of paper, board, plastic sheets, and other similar products. You can use your own scanners, cameras, or other devices to create input files for precise analysis on the PapEye CloudQCS platform. If you prefer, Tapio can also provide hardware suitable for your needs. For inquiries, trials, or demo requests, please contact us at <a href="mailto:info@tapiotechnologies.com">info@tapiotechnologies.com</a>.
        </big>

        </p>

        <div class="row margins">
          <div class="col-sm-4 d-flex align-items-stretch">
            <div class="card">
              <img class="card-img-top" src={crepe_image} alt="Crepe counter"/>
              <div class="card-body">
                <h5 class="card-title">Crepe Analysis</h5>
                <p class="card-text">PapEye Crepe Analysis tool is a simple and fast method for crepe analysis of a tissue sample. No need to use dedicated analysis instrument for a crepe analysis. Simply use a camera for an image file and have PapEye CloudQCS provide you with an instant check of your production for blade, chemical or softness performance. </p>
        <div class="d-flex flex-row justify-content-center mt-auto">
        <a href="mailto:info@tapiotechnologies.com" class="btn btn-primary">Request demo</a>
              </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 d-flex align-items-stretch">
            <div class="card">
              <img class="card-img-top" src={mottling_image} alt="Mottling analysis"/>
              <div class="card-body">
                <h5 class="card-title">Mottling Analysis</h5>
                <p class="card-text">PapEye Mottling Analysis tool is designed for a simple and accurate print mottling analysis. Use a regular scanner to create input files and upload them to PapEye Mottling tool to evaluate the quality of the print.</p>
        <div class="d-flex flex-row justify-content-center mt-auto">
        <a href="mailto:info@tapiotechnologies.com" class="btn btn-primary">Request demo</a>
              </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 d-flex align-items-stretch">
            <div class="card">
              <img class="card-img-top" src={graininess_image} alt="Graininess analysis"/>
              <div class="card-body">
                <h5 class="card-title">Graininess Analysis</h5>
                <p class="card-text">PapEye Graininess Analysis tool is designed for a simple and accurate print graininess analysis. Use your scanner to create input files and upload them to PapEye Mottling tool to evaluate the quality of the print.</p>
        <div class="d-flex flex-row justify-content-center mt-auto">
            <a href="mailto:info@tapiotechnologies.com" class="btn btn-primary">Request demo</a>
              </div>
              </div>
            </div>
          </div>
        </div>


        </Container>
        </React.Fragment>
  )
}
export default Ad
