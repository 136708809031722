import {
  GET_IMAGES,
  DELETE_IMAGE,
  GET_ACTIVE_ANALYSIS,
  DELETE_ACTIVE_ANALYSIS,
} from './ImagesTypes.js'

const initialState = {
  images: [],
  active_analysis: { active_analysis: null, timeleft: null },
}

export const imagesReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_IMAGES:
    return {
      ...state,
      images: action.payload.images,
      count: action.payload.count
    }
  case DELETE_IMAGE:
    console.log(state.images)
    return {
      ...state,
      images: state.images.filter(
        (item, index) => item.id !== action.payload
      ),
      count: state.count - 1
    }

  case GET_ACTIVE_ANALYSIS:
    return {
      ...state,
      active_analysis: action.payload,
    }

  case DELETE_ACTIVE_ANALYSIS:
    return {
      ...state,
      active_analysis: {},
    }

  default:
    return state
  }
}
