import React from 'react'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'

import { BrowserRouter } from 'react-router-dom'


import rootReducer from './Reducer'

import { setCurrentUser, setToken } from './components/login/LoginActions'
import { isEmpty } from './utils/Utils'

import axios from 'axios'
import { unsetCurrentUser } from './components/login/LoginActions'

const Root = ({ children, initialState = {} }) => {
  const middleware = [thunk]

  const store = createStore(
    rootReducer(),
    initialState,
    applyMiddleware(...middleware)
  )

  if (!isEmpty(localStorage.getItem('token'))) {
    store.dispatch(setToken(localStorage.getItem('token')))
  }
  if (!isEmpty(localStorage.getItem('user'))) {
    const user = JSON.parse(localStorage.getItem('user'))
    store.dispatch(setCurrentUser(user, ''))
  }

  // AXIOS INTERCEPT 401 status and log out user
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      console.log('error')
      console.log(error)
      console.log(error.response)
      if (error.response){
        if (error.response.status === 401) {
          console.log('Error')
          store.dispatch(unsetCurrentUser())
        }
      }
      return Promise.reject(error)
    }
  )

  return (
    <BrowserRouter>
      <Provider store={store}>
        {children}
      </Provider>
    </BrowserRouter>
  )
}

export default Root


