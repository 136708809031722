import React, {
  useEffect,
  useState
} from 'react'
import axios from 'axios'

import {
  useDispatch,
  useSelector
} from 'react-redux'

import {
  toast
} from 'react-toastify'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import {
  Container,
  Button,
  ButtonGroup,
  Row,
  Col,
  Alert,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton'
import TopNav from 'components/TopNav'
import MottlingDrop from 'components/mottling/MottlingDrop'

import DataTable from 'react-data-table-component'

import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
  faFile
} from '@fortawesome/free-solid-svg-icons';


import {
  useNavigate
} from 'react-router-dom'
import {
  connect
} from 'react-redux'


import {
  getMottlings,
  deleteMottling,
  addMottling,
  updateMottling
} from 'components/mottling/MottlingActions'

import {
  LineChart,
  Line,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'



function Mottling(props) {

  const dispatch = useDispatch()
  const state = useSelector(state => state.mottlings)

  const upload_email = props.ui_settings?.upload_email || false



  useEffect(() => {
    dispatch(getMottlings(props.app_id, 1, 10))
  }, [props.app_id])


  const handleChangeName = (id) => (event) => {
    if (event.target.value.length === 0) return

    dispatch(updateMottling(id, {
      name: event.target.value,
    }, props.app_id))
    toast.success('Saved name')
  }



  return (
    <React.Fragment>
      <React.Fragment>
        <TopNav />
      </React.Fragment>
      <Container>
        <br />
<div className="row">
    <div className="col">
        <h4>{props.ui_settings.title}</h4>
    </div>
    <div className="col text-end">
    {upload_email &&
    <p className="mb-0">Upload by email: {props.ui_settings.upload_email}</p>
    }
    </div>
</div>
        <MottlingDrop key={props.app_id} app_id={props.app_id} ui_settings={props.ui_settings}/>
        <br />
        <MottlingTable
          ui_settings={props.ui_settings}
          app_id={props.app_id}
          data={state.mottlings}
          totalRows={state.count}
          handleChangeName={handleChangeName}
        />
      </Container>
    </React.Fragment>
  )
}

function MottlingPlot(props) {
  const data = props.data.map((e) => {
    return {
      label: e[0] + ' cm',
      value: e[1].toFixed(2)
    }
  })

  return (
    <React.Fragment>
      <ResponsiveContainer height={300}>
        <LineChart
          margin={{ top: 50, right: 35, left: 10, bottom: 25 }}
          data={data}
        >
          <XAxis dataKey="label" interval={0} angle={-40}>
            <Label value="Flock size" dy={30}/>
          </XAxis>
          <YAxis>
            <Label value={props.ui_settings.result_name} position="outsideCenter" dx={-20} angle={-90}/>
          </YAxis>

          <Tooltip />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  )
}

function MottlingDetails(props) {


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('enter')
      props.handleChangeName(props.data.id)(event)
      event.preventDefault()
    }
  }

  return (
    <React.Fragment>
      <br />
      <Container>

        <Row>
          <Col xs={5}>
            <br />
            <Image width="70%" src={props.data.inputfile} />
          </Col>
          <Col xs={7}>
            <MottlingPlot ui_settings={props.ui_settings} data={props.data?.mottling_detail || []} />
          </Col>
        </Row>
        <br/>
        <Row>
          <Col xs={3}>
            <Form onSubmit={e => { e.preventDefault() }}>
              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Edit name</Form.Label>
                <Form.Control
                  placeholder={props.data.name}
                  onBlur={props.handleChangeName(props.data.id)}
                  onKeyDown = {handleKeyDown}
                />
              </Form.Group>

              <div>
              Input file: <a href={props.data.original_input}><i className="fas fa-file"></i> {props.data.original_input_name}</a>
              </div>

            </Form>

          </Col>
        </Row>
      </Container>
      <br />
      <hr />
    </React.Fragment>
  )
}

function MottlingTable(props) {

  const dispatch = useDispatch()



  const generateColumns = (ui_settings) => {
    const columnNames = ui_settings.columns || ["timestamp", "name", "barcode", "dimensions", "result"]
    const locale = ui_settings.locale || "fi-FI"
    console.log("ui_settings")
    console.log(ui_settings.locale)
    console.log(ui_settings)

    console.log(columnNames)
    console.log(ui_settings)
    const columnDefinitions = []
    columnNames.forEach((columnName) => {
      switch (columnName) {
        case 'timestamp':
          columnDefinitions.push({
            name: 'Timestamp',
            selector: (row) => new Date(row.created_at).toLocaleString(locale),
          })
          break

        case 'name':
          columnDefinitions.push({
            name: 'Name',
            selector: (row) => row.name,
            wrap: true
          })
          break

        case 'barcode':
          columnDefinitions.push({
            name: 'Barcode',
            selector: (row) => row.barcode,
          })
          break

        case 'dimensions':
          columnDefinitions.push({
            name: 'Dimensions (mm)',
            selector: (row) => {
              if (typeof row.width === 'number' && typeof row.height === 'number' && typeof row.dpi === 'number' && row.dpi !== 0) {
                return `${(row.width / row.dpi * 25.4).toFixed(2)} x ${(row.height / row.dpi * 25.4).toFixed(2)}`
              } else {
                return ''
              }
            }
          })
          break

        case 'result':
          columnDefinitions.push({
            name: ui_settings.result_name,
            selector: (row) => {
              const value = row.mottling_value
              return (
                <h3>
                {typeof value === "number" ? value.toFixed(2) : ""}
              </h3>
              )
            }
          })
          break

        default:
          break
      }
    })


    return columnDefinitions;

  }

  const columns = generateColumns(props.ui_settings)

  const data = props.data

  const history = useNavigate()

  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)


  const handlePageChange = newPage => {
    setPage(newPage)
  }
  const handlePerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage)
  }

  useEffect(() => {
    setPage(1)
  }, [props.app_id])

  useEffect(() => {
    dispatch(getMottlings(props.app_id, page, perPage))
    const delay = 5
    let timer = setInterval(() => dispatch(getMottlings(props.app_id, page, perPage)), delay * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [props.app_id, page, perPage])


  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows)
    console.log(state.selectedRows)
  }, [])

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        const ids = selectedRows.map((r) => r.id)
        console.log('ids: ', ids)
        for (const id of ids) {
          console.log('Calling delete mottling')
          dispatch(deleteMottling(id, props.app_id))
        }
        setToggleCleared(!toggleCleared)
      }
    }

    const handleExport = () => {
      const ids = selectedRows.map((r) => r.id)

      axios({
        url: '/api/mottling_export/',
        method: 'POST',
        responseType: 'blob',
        data: {
          ids: ids
        },
      }).then((response) => {
        console.log(response)

        let blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          url = window.URL.createObjectURL(blob)

        window.open(url)
      })
    }

    const routeChange = () => {
      //let path = "analysis/";
      //history.push(path);
      return
    }

    return (
      <Container>
        <ButtonGroup>
          <Button
            style={{ whiteSpace: 'nowrap' }}
            variant="info"
            key="create"
            onClick={handleExport}
          >
              Export
          </Button>
          <Button variant="danger" key="delete" onClick={handleDelete}>
              Delete
          </Button>
        </ButtonGroup>
      </Container>
    )
  }, [data, selectedRows, toggleCleared])

  return (
    <React.Fragment>
      <DataTable
        key={props.app_id + 'datatable'}
        title="Analysis results"
        columns={columns}
        data={data}
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        pagination
        paginationServer
        paginationTotalRows={props.totalRows}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        onChangeRowsPerPage={handlePerPageChange}
        onChangePage={handlePageChange} expandableRows
        expandableRowsComponent={MottlingDetails}
        expandableRowsComponentProps={{
          handleChangeName: props.handleChangeName,
          ui_settings: props.ui_settings,
        }}
      />
    </React.Fragment>
  )
}


export default Mottling
