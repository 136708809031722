import React, {
  useState
} from 'react'

import {
  connect
} from 'react-redux'
import PropTypes from 'prop-types'
import {
  Container,
  Button,
  Row,
  Col,
  Form
} from 'react-bootstrap'

import {
  login
} from 'components/login/LoginActions.js'
import logo from 'static/tapio_logo.png'

import TopNav from 'components/TopNav'

import {
  useNavigate
} from 'react-router-dom'

function Login(props) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  let navigate = useNavigate()

  const onLoginClick = () => {
    const userData = {
      username: username,
      password: password,
    }
    props.login(userData, '/home', navigate)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onLoginClick()
    }
  }


  return (

    <React.Fragment>
    <TopNav />

    <Container className="mt-5">
    <center>
    <img height="40" className="d-inline-block align-top" src={logo} />
    </center>
    <Row className="justify-content-center">


    <Col md="auto">
    <br />
    <br />
      <h5>PapEye CloudQCS login</h5>
    <Form>
    <Form.Group controlId="emailId" className="mt-2">
    <Form.Control
    type="text"
    name="username"
    placeholder="Username"
    value={username}
    onChange={(event) => setUsername(event.target.value)}
    onKeyDown = {handleKeyDown}
    />
    </Form.Group>

    <Form.Group controlId="passwordId" className="mt-2">
    <Form.Control
    type="password"
    name="password"
    placeholder="Password"
    value={password}
    onChange={(event) => setPassword(event.target.value)}
    onKeyDown = {handleKeyDown}
    />
    </Form.Group>
    </Form>
    <Button color="primary" onClick={onLoginClick} className="mt-2">
    Log in
    </Button>
    </Col>
    </Row>
    </Container>
    </React.Fragment>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, {
  login,
})(Login)
