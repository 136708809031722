import React from 'react'
import ReactDOM from 'react-dom'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootswatch/dist/flatly/bootstrap.min.css'
import 'index.css'
import App from 'App'
import reportWebVitals from './reportWebVitals'
import Favicon from 'react-favicon'
import tapiofavicon from 'static/tapio_favicon.png'


ReactDOM.render(
  <React.StrictMode>
    <Favicon url={tapiofavicon} />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
