import axios from 'axios'
import { toast } from 'react-toastify'
import { SET_TOKEN, SET_CURRENT_USER, UNSET_CURRENT_USER } from 'components/login/LoginTypes'
import { setAxiosAuthToken, toastOnError } from 'utils/Utils'

export const login = (userData, redirectTo, navigate) => (dispatch) => {
  console.log("Login action")
  axios
    .post('/api/v1/token/login/', userData)
    .then((response) => {
      const { auth_token } = response.data
      console.log(response.data)
      setAxiosAuthToken(auth_token)
      dispatch(setToken(auth_token))
      dispatch(getCurrentUser(redirectTo, navigate))
    })
    .catch((error) => {
      dispatch(unsetCurrentUser())
      toastOnError(error)
    })
}

export const getCurrentUser = (redirectTo, navigate) => (dispatch) => {
  console.log('Getting user in actions')
  axios
    .get('/api/v1/users/me/')
    .then((response) => {
      const user = {
        username: response.data.username,
        email: response.data.email,
        tabs: response.data.tabs
      }
      dispatch(setCurrentUser(user, redirectTo, navigate))
    })
    .catch((error) => {
      dispatch(unsetCurrentUser())
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.hasOwnProperty('data') &&
          error.response.data.hasOwnProperty('detail') &&
          error.response.data['detail'] === 'User inactive or deleted.'
        ) {
          //dispatch(push('/resend_activation'))
          // fix this later
        }
      } else {
        toastOnError(error)
      }
    })
}

export const setCurrentUser = (user, redirectTo, navigate) => (dispatch) => {
  localStorage.setItem('user', JSON.stringify(user))
  dispatch({
    type: SET_CURRENT_USER,
    payload: user,
  })

  if (redirectTo !== '') {
    navigate(redirectTo)
  }
}

export const setToken = (token) => (dispatch) => {
  setAxiosAuthToken(token)
  localStorage.setItem('token', token)
  dispatch({
    type: SET_TOKEN,
    payload: token,
  })
}

export const unsetCurrentUser = (navigate) => (dispatch) => {
  setAxiosAuthToken('')
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  dispatch({
    type: UNSET_CURRENT_USER,
  })
}

export const logout = (navigate) => (dispatch) => {
  axios
    .post('/api/v1/token/logout/')
    .then((response) => {
      dispatch(unsetCurrentUser(navigate))
      toast.success('Logout successful.')
    })
    .catch((error) => {
      dispatch(unsetCurrentUser(navigate))
      toastOnError(error)
    })
}
